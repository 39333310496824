import React from 'react';
import ReactDOM from 'react-dom';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import AUDIO_FILE from '../audio/Introductions.mp3';

ReactDOM.render(
  <AudioPlayer autoPlay src={AUDIO_FILE} showLoopControl={false} />,
  document.querySelector('#audio'),
);
